@import '../../styles/variables';
.button {
  height: 48px;
  width: 100%;
  border-radius: 24px;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  outline: none;
  border: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  gap: 4px;
  align-items: center;
  transition: 0.5s;
  font-family: "Red Hat Display";
  &:hover {
    opacity: 0.9;
  }

  &:disabled {
    cursor: inherit;
  }

  &.primary {
    background: $main-color;
    color: #fff;

    &:disabled {
      background-color: #e5e7eb;
      color: #9ca3af;
    }
  }
  
  &.secondary {
    &:disabled {
      background-color: #e5e7eb;
      color: #9ca3af;
    }
  }
  &.outlined {
    border: 1px solid #1b4458;
    background-color: #fff;
    color: #1b4458;
  }
  @media screen and (max-width:$breakpoint-small) {
    height: 40px;
  }
}
