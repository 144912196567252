@import "../../styles/variables.scss";

.container {
    margin-top: 10px;

    .text {
        font-size: 14px;
        font-weight: 400;

        .link {
            color: $color-blue;
            font-weight: 500;
            cursor: pointer;
        }
    }

}

.title {
    h2 {
        margin-top: 0px !important;
    }
}

.flex {
    gap: 20px;
    margin-top: 24px;
}