@import "../../styles/variables.scss";

input:placeholder {
  color: $input-label;
  font-family: "Red Hat Display", sans-serif !important;
}

.googleIcon {
  padding-right: 8px;
}

.bg {
  height: 100vh;
  position: relative;
  background: linear-gradient(140.74deg,
      #f8dce0 2.62%,
      #f5dde7 13.85%,
      #efdeef 25.74%,
      #ecdff5 37.06%,
      #e6e0fe 48.81%,
      #e9dfff 58.88%,
      #ecdcfd 69.22%,
      #efdafc 79.71%,
      #f3d8fb 89.08%,
      #f5d6fa 98.97%);

  @media screen and (max-width: $breakpoint-medium) {
    height: auto;
  }
}

.logo {
  padding-top: 54px;
  padding-left: 75px;
  display: inline-block;

  @media screen and (max-width: $breakpoint-large) {
    padding-top: 22px;
    padding-left: 45px;
  }

  @media screen and (max-width: $breakpoint-small) {
    padding-left: 16px;
  }
}

form {
  padding: 0 !important;
  width: 100% !important;
}

.signup_page {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: $breakpoint-medium) {
    justify-content: center;
  }

  .create,
  .createContent {
    text-align: center;
  }

  h2 {
    font-weight: 700;
    font-size: 28px;
    line-height: 37px;
    color: $title-color;
    margin-bottom: 24px;

    @media screen and (max-width: $breakpoint-large) {
      font-size: 25px;
      text-align: center;
    }

    @media screen and (max-width: $breakpoint-medium) {
      font-size: 22px;
      margin-bottom: 16px;
    }
  }

  h6 {
    font-size: 15px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: $title-color;
  }

  .sign_form {
    max-width: 628px;
    width: 40%;
    background: $white-color;
    border: 1px solid #e8e1f6;
    border-radius: 40px 0px 0px 40px;
    padding: 56px 96px;
    position: absolute;
    right: 0;
    top: 0;
    height: 100vh;
    display: flex;
    align-items: center;

    .radioContainer {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-bottom: 20px;
    }

    .rememberText {
      padding-left: 10px;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
    }

    @media screen and (max-width: $breakpoint-medium) {
      border-radius: 25px 24px 0px 0px;
      width: 100%;
      position: static;
      border: 0;
      max-width: 100%;
      padding: 20px;
      height: auto;
      justify-content: center;
    }

    @media screen and (max-width: $breakpoint-small) {
      padding: 24px 16px !important;
    }

    .create_ac {
      margin-top: 32px;
    }

    .forgotUI {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      gap: 10px;
    }

    .loginBtnContainer {
      width: 50%;
    }

    .input_field {
      height: 64px;
      background: $table-row-bg;
      border-radius: 12px;
      margin-bottom: 12px;
      width: 100%;
      text-align: left;
      display: flex;
      align-items: center;
      position: relative;

      img {
        position: absolute;
        left: 15px;
      }

      input {
        width: 100%;
        background: transparent;
        border: 0;
        outline: 0;
        padding-left: 65px;
        height: 64px;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        letter-spacing: 0.02em;
        color: $input-label;
      }
    }

    .verifyBtn {
      margin: 40px 0;
    }

    .verify {
      text-align: center;

      button {
        @media screen and (max-width: $breakpoint-medium) {
          margin: 30px 0;
        }
      }

      a {
        font-size: 16px;
        font-weight: 700;
        line-height: 21px;
        letter-spacing: 0em;
        text-align: center;
        color: $color-yellow;
      }
    }

    .connectText {
      font-weight: 400;
      font-size: 15px;
      line-height: 140%;
      color: $title-color;
      margin-bottom: 24px;
    }

    p {
      color: $title-color;
      font-size: 15px;
      line-height: 140%;
      margin-bottom: 10px;
    }

    .connectWith {
      margin-bottom: 12px;

      @media screen and (max-width: $breakpoint-allmobile) {
        margin-bottom: 16px;
        text-align: center;
      }
    }
  }
}

.modalContainer {
  // display: flex !important;
  text-align: center;

  h5 {
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 37px;
    text-align: center;
    color: $title-color;

    @media screen and (max-width: $breakpoint-small) {
      font-size: 20px;
    }
  }

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    color: $input-label;
    margin-top: 16px;
    margin-bottom: 48px;
  }

  .modalBtn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
  }
}

// login page css


.input_group {
  margin-bottom: 16px;

  label {
    display: block;
    text-align: left;
    // margin-bottom: 8px;
  }

  input {
    height: 48px;
    background: transparent;
    border: 1px solid var(--input-border);
    border-radius: 12px;
    width: 100%;
    padding: 14px 16px;
  }

  .btn {
    box-sizing: border-box;
    max-width: 436px;
    height: 48px;
    width: 100%;
    border: 1px solid $title-color;
    border-radius: 24px;
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    color: $btn-text-color;
    background: $color-white;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    @media screen and (max-width: $breakpoint-medium) {
      max-width: 100%;
    }

    span {
      padding-right: 8px;
      height: 20px;
    }
  }
}
.checkboxsize {
  margin-top: 0px !important;
}

.input_checkbox {
  margin: 26px 0 20px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    input {
      background: var(--input-border);
      width: 16px;
      height: 18px;
      -webkit-transform: scale(1.1, 1.1);
    }
  }

  .checklabel {
    padding-left: 12px;
    line-height: normal;
  }

  span {
    font-size: 15px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
    color: $title-color;
    font-weight: bold;
  }
}

.login {
  button {
    margin-top: 40px;

    @media screen and (max-width: $breakpoint-medium) {
      margin-top: 24px;
    }
  }
}

.or {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 26px 0px 26px 0px;
  color: $title-color;

  @media screen and (max-width: $breakpoint-medium) {
    margin: 20px 0;
  }

  @media screen and (max-width: $breakpoint-small) {
    margin: 26px 0;
  }

  span {
    height: 1px;
    width: 45%;
    background: $input-border;
  }
}

.registered {
  margin-top: 40px;

  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
    color: $input-label;

    a {
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0em;
      text-align: center;
      color: $input-label;
    }

    span {
      font-size: 16px;
      font-weight: 700;
      line-height: 21px;
      letter-spacing: 0em;
      text-align: center;
      color: $color-yellow;
      margin-left: 5px;
    }
  }
}

.sign_img {
  width: 60%;
  height: 100%;
  transform: translateY(-50%);
  position: absolute;
  top: 46%;

  @media screen and (max-width: $breakpoint-medium) {
    position: static;
    transform: translateY(0);
  }

  .img {
    width: 100%;
    height: 100%;
    text-align: center;

    svg {
      width: 100%;
      height: 100%;

      @media screen and (max-width: $breakpoint-medium) {
        width: 100%;
        height: auto;
      }

      @media screen and (max-width: $breakpoint-small) {
        margin-top: -40px;
        padding-top: 60px;
      }
    }

  }

  .diversify_text {
    max-width: 380px;
    margin: auto;
    text-align: center;
    min-height: 90px;

    h4 {
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      color: $title-color;
    }

    p {
      margin-top: 10px;
      color: $peragraph-text;
      text-align: center;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
    }

    @media screen and (max-width: $breakpoint-medium) {
      display: none;
    }
  }
}


// Verify Email address UI css

.verify_code {
  margin: 32px 0 40px;
  display: flex;
  align-items: center;
  justify-content: space-around;

  .reactOtp {
    justify-content: space-between;
    width: 100%;

    @media screen and (max-width: $breakpoint-allmobile) {
      justify-content: center;
    }
  }

  .inputOtp {
    width: 46px !important;
    border: 1px solid $input-border !important;
    border-radius: 12px !important;
    outline: none !important;

    @media screen and (max-width: $breakpoint-small) {
      width: 38px !important;
    }

    &:focus {
      border: 1px solid $title-color;
    }
  }

  input {
    width: 46px;
    height: 56px;
    background: $light-gray;
    border: 1px solid $input-border;
    border-radius: 12px;
    text-align: center;
  }
}

.resendCode {
  margin-top: 43px;
  text-align: center;

  @media screen and (max-width: $breakpoint-medium) {
    margin-top: 26px;
  }

  span {
    font-size: 16px;
    font-weight: 700;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: center;
    text-decoration: none;
    color: $color-yellow;
    cursor: pointer;
  }
}

@media screen and(max-width:$breakpoint-large) {
  .signup_page .sign_form {
    padding: 25px;

    .connectText {
      text-align: center;
    }
  }

  .verify_code input {
    width: 35px;
    height: 45px;
  }
}

@media screen and(max-width:$breakpoint-small) {
  .sign_form {
    .connectText {
      text-align: center;
    }
  }
}

@media screen and(max-width:1199px) {
  .signup_page .sign_form {
    padding: 45px;
  }
}

.set_width_res {
  @media screen and (max-width: 767px) {
    width: 100%;
  }
}