@import "../styles/variables.scss";

.layout {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  background-color: #f6f6f7;
  width: 100%;

  .mainClass {
    width: calc(100vw - 250px);
    padding-right: 40px;
    padding-left: 40px;
    // width: 100%;
    // max-width: 1110px;
    background-color: #f6f6f7;

    @media screen and (max-width: $breakpoint-large) {
      padding-right: 20px;
      padding-left: 20px;
    }

    @media screen and (max-width: $breakpoint-allmobile) {
      width: 100vw;
      padding: 0 16px;
    }

    .content {
      width: 100%;
      height: 100vh;
      overflow: scroll;
      padding: 100px 0 73px;
      position: relative;
      margin-top: 5px;

      h1{
        color: $title-color;
      }

      .spinner {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      @media screen and(max-width:$breakpoint-medium) {
        padding: 70px 0 40px;
      }
    }
  }

  .activeDashboard {
    width: calc(100vw - 90px) !important;
    transition: 0.5s;

    @media screen and (max-width: $breakpoint-allmobile) {
      width: 100vw !important;
      padding: 0 16px;
    }
  }
}