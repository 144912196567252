$breakpoint-xsmall: 320px;
$breakpoint-small: 576px;
$breakpoint-medium: 768px;
$breakpoint-large: 992px;
$breakpoint-allmobile: 767px;
$breakpoint-extra-large: 1300px;
$breakpoint-extra-extra-large: 1500px;

// =========== *** Color Variables *** ==================
$black-color: #000;
$pink-color: #FF008A; 
$light-black-color: #1e1e1e;
$white-color: #fff;
$light-gray: #fafafa;
$input-border: #dddeed;
$title-color: #1b4458;
$input-placeholder: hsla(200, 53%, 23%, 0.5);
$input-label: hsla(200, 53%, 23%, 0.7);
$btn-disabled: #e5e7eb;
$table-th: #9599b5;

$title-color: #190044;
$text-danger: #f87171;
$btn-text-color: hsla(200, 53%, 23%, 0.7);
$title-color: #9c86ce;
$color-white: #ffffff;

$purple-light: #e8e1f6;

$btn-disabled-color: #9ca3af;
$color-orange: #ffb000;
$color-yellow: #ffb000;
$color-blue: #3e3aff;

$table-row-bg: #f9f7fc;
$peragraph-text: hsla(200, 53%, 23%, 0.7);
$tableheader: #9599b5;
$tabButton: #1e1e1e;

$main-gradient: linear-gradient(140.74deg, #B1D0DF 2.62%, #BAD6E3 13.85%, #A8C8D8 25.74%, #B7D0DC 37.06%, #C2DAE5 48.81%, #CBD3EC 58.88%, #BCC6E6 69.22%, #A5AFD3 79.71%, #909DC7 89.08%, #A3AED1 98.97%);
$main-color: #1b4458;
$main-color-70: hsla(200, 53%, 23%, 0.7);
$main-color-50: hsla(200, 53%, 23%, 0.5);
$main-color-20: hsla(200, 53%, 23%, 0.2);
$main-color-10: hsla(200, 53%, 23%, 0.1);
$label-color: hsla(200, 53%, 23%, 0.7);
$title-color: #1b4458;

:export {
  breakpoint-xsmall: $breakpoint-xsmall;
  breakpoint-small: $breakpoint-small;
  breakpoint-medium: $breakpoint-medium;
  breakpoint-large: $breakpoint-large;
  breakpoint-extra-large: $breakpoint-extra-large;

  black-color: $black-color;
  pink-color: $pink-color;
  white-color: $white-color;
  light-gray: $light-gray;
  input-border: $input-border;
  dark-blue: $title-color;
  input-placeholder: $input-placeholder;
  input-label: $input-label;
  btn-disabled: $btn-disabled;

  text-dark: $title-color;
  text-danger: $text-danger;
  btn-text-color: $btn-text-color;
  btn-light-border: $title-color;
  color-white: $color-white;

  purple-light: $purple-light;

  btn-disabled-color: $btn-disabled-color;
  color-orange: $color-orange;
  color-blue: $color-blue;

  table-row-bg: $table-row-bg;
  peragraph-text: $peragraph-text;

  main-gradient: $main-gradient;
  main-color: $main-color;
  main-color-70: $main-color-70;
  main-color-50: $main-color-50;
  main-color-20: $main-color-20;
  main-color-10: $main-color-10;
  label-color: $label-color;
  title-color: $title-color;
}

$select-bg: rgba(156, 134, 206, 0.6);
