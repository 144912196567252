@import "../../styles/variables.scss";

.menuItem {
    font-size: 14px !important;
    font-weight: 400;
    font-family: "Red Hat Display", sans-serif !important;
    display: flex;
    align-items: center;
    justify-content: flex-start !important;
    gap: 5px;

    span {
        font-size: 14px !important;
        font-weight: 500;
        font-family: 'Nunito Sans', sans-serif !important;
    }

    svg {
        width: 20px;
        height: 20px;
    }
}