@import "../../styles/variables.scss";

.sidebar {
  max-width: 256px;
  width: 100%;
  height: 100vh;
  background: $white-color;
  border-right: 2px solid $main-color-20;
  border-radius: 0px 30px 0px 0px;
  position: relative;
  display: inline-block;
  transition: 0.5s;
  z-index: 9;

  @media screen and(max-width:$breakpoint-allmobile) {
    position: absolute;
    left: 0;
    transform: translateX(-260px);
  }

  &.active {
    max-width: 90px;
    width: 100%;

    @media screen and(max-width:$breakpoint-allmobile) {
      display: none;
    }




    &.active {
      max-width: 90px;
      position: relative;
      width: 100%;

      .activeDropMenu {
        .opendropmenu {
          display: none !important;

          @media screen and(max-width:$breakpoint-allmobile) {
            display: block !important;
          }
        }
      }

      @media screen and(max-width:$breakpoint-allmobile) {
        display: block;
        max-width: 100%;
        width: 100%;
        position: fixed;
        border-radius: 0;
        transition: 0.5s;
        z-index: 99;
        transform: translateX(0);
      }

      .menu {
        li {
          padding: 12px 12px 12px 30px;

          @media screen and(max-width: $breakpoint-allmobile) {
            padding: 12px 12px 12px 40px;
          }

          p {
            display: none;

            @media screen and(max-width: $breakpoint-allmobile) {
              display: block;
            }
          }
        }
      }

      .arrow {
        display: none;

        @media screen and(max-width: $breakpoint-allmobile) {
          display: block;
        }
      }

      .logo {
        padding: 24px 0 40px 25px;

        @media screen and(max-width: $breakpoint-allmobile) {
          padding: 18px 0 24px 59px;
          position: fixed;
          width: 100%;
          background-color: $white-color;
        }
      }

      .loggle {
        svg {
          transform: rotate(180deg);
          transition: 0.5s;
        }
      }
    }

    .loggle {
      width: 40px;
      height: 40px;
      position: absolute;
      right: -18px;
      top: 50%;
      transform: translateY(-50%);
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #e8e1f6;
      border-radius: 50%;
      background-color: $white-color;
      cursor: pointer;
      z-index: 9;

      @media screen and (max-width: $breakpoint-allmobile) {
        top: 28px;
        left: 12px;
        transform: translateY(0);
        border: 0;
        border-radius: 0;
        z-index: 99999;

        .open {
          display: none;
        }
      }

      .close {
        display: none;

        @media screen and (max-width: $breakpoint-allmobile) {
          display: block;
        }
      }
    }

    .logo {
      padding: 24px 0 40px 40px;
      z-index: 999;

      @media screen and(max-width:$breakpoint-large) and (min-width: $breakpoint-medium) {
        padding: 12px 12px 12px 16px;
      }
    }

    .menu {
      li {
        padding: 12px 12px 12px 30px;

        p {
          display: none;

          @media screen and(max-width:$breakpoint-allmobile) {
            display: block;
          }
        }
      }
    }

    .logo {
      padding: 24px 0 40px 25px;

      @media screen and(max-width: $breakpoint-allmobile) {
        padding: 24px 0 40px 50px;
      }
    }

    .loggle {
      svg {
        transform: rotate(180deg);
        transition: 0.5s;
      }
    }
  }

  .loggle {
    width: 40px;
    height: 40px;
    position: absolute;
    right: -18px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #e8e1f6;
    border-radius: 50%;
    background-color: $white-color;
    cursor: pointer;
    z-index: 9;

    @media screen and (max-width: $breakpoint-allmobile) {
      top: 34px;
      left: 0;
      transform: translateY(0);
      border: 0;
      border-radius: 0;

      .open {
        display: none;
      }
    }

    .close {
      display: none;

      @media screen and (max-width: $breakpoint-allmobile) {
        display: block;
      }
    }
  }

  .scroll {
    height: 100vh;
    overflow: scroll;
  }

  .logo {
    padding: 24px 0 40px 40px;
    @media screen and(max-width:$breakpoint-large) and (min-width: $breakpoint-allmobile) {
      padding: 12px 12px 12px 16px;
    }

    svg {
      
      @media screen and (max-width: $breakpoint-allmobile) {
        height: 40px;
        margin-top: 8px;
      }
    }
  }

  .menu {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100vh - 131px);

    @media screen and (max-width: $breakpoint-allmobile) {
      margin-top: 90px;
    }

    .dropMenu {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .arrow {
        line-height: normal;
        padding-left: 12px;
        cursor: pointer;
        padding-right: 0;
        height: 20px;
      }

      p {
        font-size: 16px;
        font-weight: 500;
        line-height: 21px;
        letter-spacing: 0.02em;
        text-align: left;
        color: $title-color;
        cursor: pointer;
      }

      .opendropmenu {
        display: none;
        padding: 0;

        li {
          span {
            display: none;
          }
        }
      }
    }

    .activeDropMenu {
      color: $title-color;
      .opendropmenu {
        display: block;
        width: 100%;
        // margin-left: -40px;

        @media screen and(max-width:$breakpoint-large) and (min-width: $breakpoint-allmobile) {
          margin-left: -16px;
        }

        li {
          padding-left: 76px;

          &::after {
            display: none;
          }

          @media screen and(max-width:$breakpoint-large) and (min-width: $breakpoint-allmobile) {
            padding-left: 55px;
          }
        }
      }

      .arrow {
        transform: rotate(270deg);
        padding-left: 0 !important;
        padding-top: 10px;
      }
    }

    .LogOut {
      position: relative;
      margin-top: 50px;
      margin-bottom: 30px;

      &::after {
        content: "";
        position: absolute;
        left: 0;
        top: -24px;
        right: 0;
        width: calc(100% - 48px);
        height: 1px;
        background-color: $input-border;
        margin: auto;
      }
    }

    .sidebarUl {
      list-style: none;

      li {
        padding: 12px 12px 12px 40px;
        position: relative;

        &.active {
          &:after {
            position: absolute;
            content: "";
            left: 0;
            height: 100%;
            width: 5px;
            background: $title-color;
            border-radius: 0px 4px 4px 0px;
            top: 0;
          }
        }

        @media screen and(max-width:$breakpoint-large) and (min-width: $breakpoint-allmobile) {
          padding: 12px 12px 12px 16px;
        }

        a {
          display: flex;
          align-items: center;
          font-size: 16px;
          font-weight: 500;
          line-height: 21px;
          letter-spacing: 0.02em;
          text-align: left;
          color: $title-color;
          // &.active {
          //   svg path {
          //     stroke: $title-color !important;
          //   }
          // }
        }

        span {
          max-height: 26px;
          padding-right: 12px;
          svg {
            width: 24px;
          }
        }

        p {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          color: $main-color-70;
        }
      }
    }
  }
}

.label {
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0.02em;
  text-align: left;
  color: $main-color-70;
  cursor: pointer;
}

.textActive {
  color: $title-color !important;
}