@import "../../styles/variables.scss";

.card {
    background: $white-color;
    position: relative;
    border-radius: 10px;
    padding: 35px 22px;
    text-align: center;
    flex: 1; // width: max-content;

  &.sm {
    padding: 12px;
  }

  &.md {
    padding: 17px;
  }

  &.lg {
    padding: 24px;
  }

  @media screen and (max-width: $breakpoint-medium) {
    width: 100%;
  }
}
