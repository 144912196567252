
@import "../../styles/variables.scss";

.title {
    display: block;

    h2 {
        margin-bottom: 20px;
        font-size: 22px;
        font-weight: 700;
        line-height: 29px;
        letter-spacing: 0em;
        text-align: left;
        color: $title-color;
        @media screen and (max-width:$breakpoint-medium) {
            margin-top: 16px;
            font-size: 18px;
        }
    }

    p {
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        color:  $main-color-70;
    }
}