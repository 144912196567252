@import "../../styles/variables.scss";

.flaticon {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;

  @media screen and (max-width: $breakpoint-medium) {
    li {
      // display: none;
    }

    li:first-child {
      display: block;
    }
  }

  a {
    display: inline-block;
  }

  .space {
    padding-left: 20px;

    @media screen and (max-width:$breakpoint-small) {
      padding-left: 10px;
    }
  }

  .logOut_avatar {
    border: 0;
    outline: none;
    cursor: pointer;
    height: 40px;
    width: 40px;
    overflow: hidden;
    border-radius: 8px;
    border: 2px solid $main-color-20;
    @media screen and (max-width:$breakpoint-small) {
      height: 30px;
      width: 30px;
    }
    svg {
      width: 100%;
      height: auto;
    }

    &:focus {
      outline: none;
      border: none;
      box-shadow: none;
    }
  }
}


img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}