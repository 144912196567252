@import "../../../styles/variables.scss";

.container {
    display: flex;
    width: 100%;
    row-gap: 20px;
    align-items: center;

}


.alignCenter {
    align-items: center;
}

.alignStart {
    align-items: flex-start;

    .margin {
        margin-bottom: 20px;
    }
}
