@import "../../styles/variables";

.notification {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  cursor: pointer;

  .notificationText {
    margin-left: 16px;
    margin-bottom: 16px;

    .subTitle {
      margin-top: 0;
      color: $title-color;
      font-size: 16px;
      margin-bottom: 4px !important;
      font-weight: 500;
    }

    .content {
      font-weight: 400;
      font-size: 15px;
      line-height: $peragraph-text;
    }

    .days {
      color: $tableheader;
      font-weight: 400;
      font-size: 15px;
    }
  }
}
