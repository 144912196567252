@import "../../../styles/variables.scss";

.flex {
    justify-content: space-between;
    margin-bottom: 20px;

    h3 {
        color: $title-color;
        font-size: 28px;
        font-weight: 700;
    }

    .flexItem {
        width: 100%;

        h4 {
            color: $title-color;
            font-size: 16px;
            font-weight: 700;
        }
    }
}



.addBtn {
    height: 32px;
    width: 75px;
    padding: 0px 15px;
    margin-right: 24px;

    text-align: center;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.28px;
}

h4 {
    color: $title-color;
    font-size: 18px;
    font-weight: 700;
}

// p {
//     color: $peragraph-text;
//     font-size: 16px;
//     line-height: 140%;
//     margin-right: 5px;
// }
