@import "../../styles/variables.scss";

.changePassword {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    .inner {
        width: 500px;
        text-align: start;

        @media (max-width: $breakpoint-small) {
            width: 100%;
        }
    }

    .btnContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 24px;

        .btn {
            width: 50%;

            @media (max-width: $breakpoint-small) {
                width: 100%;
            }
        }
    }

}

.password-rule {
    margin-top: 10px;
}

ul,
p {
    margin-top: 16px;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 140%;
    color: $input-label;
    margin-top: 5px;
}

ul,
li {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: $input-label;
    margin-top: 5px;
}