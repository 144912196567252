@import "../../styles/variables.scss";

.inputContainer {
    width: 50%;

    .radioLabel {

        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 140%;
        color: $input-label;
    }

    .inputRadio {
        display: flex;
        align-items: center;
        gap: 12px;
        margin: 8px 0px;

        .labelContainer {
            width: 95%;
        }

        // &:focus {
        //     border: 1px solid $title-color
        // }
    }

    .error {

        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: $text-danger;
        margin-right: 15px;
    }

    .textContentArea {
        padding: 14px 18px 13px 16px;
        border-radius: 12px;
        border: 1px solid $input-border;
        background: $light-gray;
        font-size: 15px;
        font-weight: 400;
        line-height: 140%;
        margin-bottom: 6px;
        width: 100%;
        resize: vertical;
        outline: none;

        &::placeholder {
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 140%;
            color: $input-placeholder;
            font-family: "Red Hat Display", sans-serif !important;
        }
    }

    .labelError {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 8px;
    }
}

.showPassword {
    cursor: pointer;

    svg {
        margin-top: 4px;
    }
}

.width0 {
    width: 20px;
}

.width100 {
    width: 100%;
    margin-bottom: 10px;
}

.datePlaceholder {
    font-family: "Red Hat Display", sans-serif !important;
}

.inputPlaceholder {
    color: $title-color;
    font-family: "Red Hat Display", sans-serif !important;
}

.inputBox {
    background-color: $light-gray;
    border-radius: 12px;
    padding: 0px 10px;
    height: 44px;
    overflow: hidden;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    &:select {
        border: 1px solid $title-color !important
    }

}

.input {
    background: transparent;
    border: none;
    outline: none;
    width: 100%;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 140%;

    &::placeholder {
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 140%;
        color: $input-placeholder;
        font-family: "Red Hat Display", sans-serif !important;
    }
}

.labelText {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 140%;
    color: $main-color-70;
}

.displayNone {
    display: none !important;
}

.borderError {
    border: 1px solid $text-danger;
}

.borderNormal {
    border: 1px solid $input-border;
}

.text_center {
    text-align: center;
}

.text_start {
    text-align: start;
}

@media (min-width: $breakpoint-medium) {
    .inputContainer {
        flex-direction: column;
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    display: none;
}

input[type='radio'] {
    width: 20px;
    height: 20px;
    accent-color: $title-color;
}

input[type=checkbox] {
    width: 15px;
    height: 15px;
    accent-color: $title-color;
}