@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@300;400;500;700;900");
@import "./styles/mixins.scss";
@import "./styles/variables.scss";

body {
  @include fontFamily;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Red Hat Display", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.fmZmYC {
  background-color: #f6f6f6 !important;

  svg {
    display: none !important;
  }
}

.fmkJzP:not(:last-of-type),
.kdYplh {
  border: none !important;
}

.joaRLv {
  background-color: transparent !important;
}

::-webkit-scrollbar {
  display: none;
}

a {
  text-decoration: none;
}

ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.react-responsive-modal-modal {
  border-radius: 30px;
  max-width: 600px !important;
  display: inline-block !important;
  width: calc(100% - 38.4px);
}

.css-13cymwt-control:hover {
  border-color: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #fafafa inset !important;
}

.material-ui-tab .css-108rbcv-MuiTabs-root {
  position: fixed;
  top: 24px;
  z-index: 9;
}

.material-ui-tab .css-108rbcv-MuiTabs-root button {
  text-transform: initial;
}

@media screen and (max-width: 1200px) {
  .material-ui-tab .css-108rbcv-MuiTabs-root {
    position: static;
  }

  .material-ui-tab .css-108rbcv-MuiTabs-root button {
    font-size: 14px;
  }
}

@media (min-width: 768px) {
  .react-responsive-modal-modal {
    width: 100%;
  }
}

.rdt_TableHeadRow {
  border-bottom: 0px;
}

.react-date-picker__wrapper {
  border: none !important;
  width: 100%;
}

.react-date-picker__inputGroup__input .react-date-picker__inputGroup__year {
  border: none;
}

.react-calendar__tile--active {
  background-color: $title-color !important;
}

.react-calendar__tile--now {
  background-color: $select-bg !important;
}

.react-date-picker__inputGroup__input .react-date-picker__inputGroup__day {
  border: none !important;
}

.loaderContainer {
  background-color: #f6f6f6;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loaderComponent {
  background-color: #f6f6f6;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.componentLoader {
  height: 70vh;
}

.MuiPaginationItem-root {
  font-family: "Red Hat Display", sans-serif !important;
  font-weight: 500 !important;
}