@import "../../styles/variables.scss";
@import '../../styles/mixins';

.search {
    position: relative;
    max-width: 300px;
    width: 100%;
    margin-right: 24px;

    @media screen and (max-width:$breakpoint-medium) {
        max-width: inherit;
        
    }

    input {
        width: 100%;
        height: 48px;
        background-color: $white-color;
        margin-top: 0;
        padding-left: 48px;
        border: 0;
        border-radius: 10px;
         @include fontFamily;

        &:focus {
            border: 0;
        }

        @media screen and (max-width:$breakpoint-medium) {
            display: none;
        }

        &::placeholder {
            font-family: "Red Hat Display", sans-serif;
        }
    }

    svg {
        position: absolute;
        left: 17px;
        transform: translateY(-50%);
        top: 50%;

        @media screen and (max-width:$breakpoint-medium) {
            right: -15px;
            left: inherit;
            height: 24px;
            width: 24px;
            top: 0;
            cursor: pointer;
        }
    }
}
